import {
  registerApplication,
  start,
} from "single-spa/lib/esm/single-spa.min.js";
import { Authentication } from "@sdi-one-internal-tools/internal-tools-app-web-utilities";

const oktaConfig = Authentication.getOktaConfig();
const subAppOktaConfig = {
  ...oktaConfig,
  tokenManager: {
    autoRenew: false, // stop auto renew for all apps except authentication App (1 app is responsible for updating tokens)
  },
};

registerApplication(
  "@sdi-one-internal-tools/authentication-internal-tools-app-web",
  () =>
    System.import(
      "@sdi-one-internal-tools/authentication-internal-tools-app-web"
    ),
  (location) => true,
  { oktaConfig: oktaConfig }
);

registerApplication(
  "@sdi-one-hardware/nms-authorization-internal-tools-app-web",
  () =>
    System.import("@sdi-one-hardware/nms-authorization-internal-tools-app-web"),
  (location) => location.pathname.startsWith("/local-compute/account-assign"),
  { oktaConfig: subAppOktaConfig }
);

registerApplication(
  "@sdi-one-internal-tools/navigation-internal-tools-app-web",
  () =>
    System.import("@sdi-one-internal-tools/navigation-internal-tools-app-web"),
  (location) => !location.pathname.startsWith("/implicit/callback"),
  { oktaConfig: subAppOktaConfig }
);

registerApplication(
  "@sdi-one-connectors/connectors-admin-console-app-web",
  () => System.import("@sdi-one-connectors/connectors-admin-console-app-web"),
  (location) => location.pathname.startsWith("/connectors/connectors"),
  { oktaConfig: subAppOktaConfig }
);

registerApplication(
  "@sdi-one-internal-tools/reports-internal-tools-app-web",
  () => System.import("@sdi-one-internal-tools/reports-internal-tools-app-web"),
  (location) => location.pathname.startsWith("/reports"),
  { oktaConfig: subAppOktaConfig }
);

start();
